import React from "react";
import { NavLink } from 'react-router-dom';

class NavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {to: props.to, text: props.text};
  }

  render() {
    return (
      <li className="nav-item">
        <NavLink to={this.state.to} className={({isActive}) => "nav-link" + (isActive ? " active" : "")}>
          {this.state.text}
        </NavLink>
      </li>
    );
  }
}

export default NavItem;