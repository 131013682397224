import React from "react";

class SelectGradeLevel extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.state = {gradeLevel: props.gradeLevel, onChange: props.onChange};
  }

  onChange(e) {
  	const value = e.target.value;
  	const gradeLevel = value === "" ? null : parseInt(value);
  	this.state.onChange(gradeLevel);
  	this.setState({gradeLevel: gradeLevel});
  }

  render() {
    return (
      <select className="form-select" value={this.state.gradeLevel === null ? "" : this.state.gradeLevel} onChange={this.onChange}>
        <option value="">--- Grade ---</option>
        <option value="0">Kindergarten</option>
        <option value="1">1st</option>
        <option value="2">2nd</option>
        <option value="3">3rd</option>
        <option value="4">4th</option>
        <option value="5">5th</option>
        <option value="6">6th</option>
        <option value="7">7th</option>
        <option value="8">8th</option>
        <option value="9">9th</option>
        <option value="10">10th</option>
        <option value="11">11th</option>
        <option value="12">12th</option>
      </select>
    );
  }
}

export default SelectGradeLevel;