import React, { Component } from "react";
import { Clipboard } from 'react-bootstrap-icons';
import { withRouter } from "../common/with-router";
import AttendanceService from "../services/attendance.service";

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.showSessions = this.showSessions.bind(this);
    this.showMembers = this.showMembers.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeAttendance = this.onChangeAttendance.bind(this);
    this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
    this.onCopySignupsToClipboard = this.onCopySignupsToClipboard.bind(this);
    this.onAddClicked = this.onAddClicked.bind(this);
    this.onChangeSearchText = this.onChangeSearchText.bind(this);
    this.onAddSignup = this.onAddSignup.bind(this);
    this.state = {
      tab: "sessions",
      location: null,
      calendars: null,
      calendarId: null,
      signups: null,
      accounts: null,
      beingSavedId: null,
      searchText: "",
      searchTimer: null,
      isSearching: false,
      searchResult: [],
    };
    AttendanceService.listDates()
    .then(resp => {
      const location = resp.data.location;
      const calendars = resp.data.calendars;
      const calendarId = this.findFirstPastCalendarId(calendars.toReversed());
      this.setState({location: location, calendars: calendars, calendarId: calendarId});
      this.getSignups(calendarId);
    }).catch(this.handleError);
    AttendanceService.listMembers()
    .then(resp => {
      var emails = "";
      for (const account of resp.data.accounts) {
        if (emails !== "") {
          emails = emails + ', ';
        }
        emails = emails + '"' + account.firstName + ' ' + account.lastName + '" <' + account.email + '>';
      }
      this.setState({accounts: resp.data.accounts, emails: emails});
    }).catch(this.handleError);
  }

  getSignups(calendarId) {
    this.setState({signups: null, beingSavedId: null})
    AttendanceService.listSignups(calendarId)
    .then(resp => {
      var emails = "";
      for (const signup of resp.data.signups) {
        const email = '"' + signup.account.firstName + ' ' + signup.account.lastName + '" <' + signup.account.email + '>';
        if (emails.indexOf(email) === -1) {
          if (emails !== "") {
            emails = emails + ', ';
          }
          emails = emails + email;
        }
      }
      this.setState({signups: resp.data.signups, signupEmails: emails});
    })
    .catch(this.handleError);
  }

  handleError(e) {
    if (e.response?.status === 403) {
      this.props.router.navigate("/login");
    } else {
      console.log(e);
    }
  }

  findFirstPastCalendarId(calendars) {
    const now = new Date();
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    for (var index = 0; index < calendars.length; ++index) {
      if (new Date(calendars[index].startTime) > yesterday) {
        return calendars[index].id;
      }
    }
    return calendars.length === 0 ? null : calendars[calendars.length - 1].id;
  }

  showSessions(e) {
    this.setState({tab: "sessions"});
  }

  showMembers(e) {
    this.setState({tab: "members"});
  }

  onChangeLocation(e) {
    const calendarId = parseInt(e.target.value);
    this.setState({calendarId: calendarId, signups: null});
    this.getSignups(calendarId);
  }

  onChangeAttendance(e) {
    const attended = e.target.value === "1";
    const signupId = parseInt(e.target.name.split("-")[1]);
    const signup = this.findSignup(signupId);
    const currentAttended = signup.attended;
    if (signup.attended === attended) {
      signup.attended = null;
    } else {
      signup.attended = attended;
    }
    this.setState({signups: this.state.signups, beingSavedId: signupId});
    AttendanceService.updateAttendance(signupId, signup.attended)
    .then(resp => {
      this.setState({beingSavedId: null});
    })
    .catch(e => {
      signup.attended = currentAttended;
      this.setState({signups: this.state.signups, beingSavedId: null});
      this.handleError(e);
    });
  }

  onCopyToClipboard() {
    navigator.clipboard.writeText(this.state.emails);
  }

  onCopySignupsToClipboard() {
    navigator.clipboard.writeText(this.state.signupEmails);
  }

  findSignup(id) {
    for (var index = 0; index < this.state.signups.length; ++index) {
      if (this.state.signups[index].id === id) {
        return this.state.signups[index];
      }
    }
    return null;
  }

  formatDatetime(datetime) {
    return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric'}).format(new Date(datetime));
  }

  signup2name(signup) {
    return "signup-" + signup.id;
  }

  signup2id(signup, yes) {
    return this.signup2name(signup) + "-" + (yes ? "1" : "0");
  }

  getGradeLevel(signup) {
    var gradeLevel = null;
    if (signup.account.role === "Student") {
      gradeLevel = signup.account.gradeLevel;
    }
    else if (signup.child !== null) {
      gradeLevel = signup.child.gradeLevel;
    }
    return gradeLevel === null ? "" : gradeLevel;
  }

  findSelectedCalendar() {
    for (const calendar of this.state.calendars) {
      if (calendar.id === this.state.calendarId) {
        return calendar;
      }
    }
    return null;
  }

  isSelectedSessionInFuture() {
    if (this.state.calendars === null || this.state.calendars.length === 0) {
      return false;
    }
    const now = new Date(new Date().getTime() + 60 * 60 * 1000);
    const sessionDate = new Date(this.findSelectedCalendar().startTime);
    return sessionDate > now;
  }

  search(q) {
    this.setState({isSearching: true});
    AttendanceService.searchMembers(this.state.calendarId, q)
    .then(resp => {
      this.setState({isSearching: false, searchResult: resp.data.result});
    });
  }

  onAddClicked(e) {
    this.setState({searchText: ""});
    this.search("");
  }

  onChangeSearchText(e) {
    if (this.state.searchTimer !== null) {
      clearTimeout(this.state.searchTimer);
    }
    const searchText = e.target.value;
    var searchTimer = setTimeout(() => this.search(searchText), 200);
    this.setState({ searchText: searchText, searchTimer: searchTimer });
  }

  onAddSignup(index) {
    const searchResult = this.state.searchResult;
    searchResult[index].state = "Adding...";
    this.setState({searchResult: searchResult});
    AttendanceService.addSignup(this.state.calendarId, this.state.searchResult[index].id, this.state.searchResult[index].childId)
    .then(resp => {
      const searchResult = this.state.searchResult;
      searchResult[index].state = "Added";
      this.setState({searchResult: searchResult});
      this.getSignups(this.state.calendarId);
    });
  }

  render() {
    return (
      <div>
        <div className="member-list table-responsive mb-3">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>
                  Name
                </th>
                <th>
                  Email
                </th>
                <th>Role</th>
                <th>Child(ren)</th>
                <th>Registration Date</th>
                <th>Last Login</th>
              </tr>
            </thead>
            <tbody>
              {this.state.accounts?.map((account, index) => (
                <tr key={index}>
                  <td>{account.firstName} {account.lastName}</td>
                  <td>{account.email}</td>
                  <td>{account.role}<br/><span className="form-text">{account.gradeLevel !== null && ("(Grade " + account.gradeLevel + ")")}</span></td>
                  <td>
                    {account.children.map((child, index) => (
                      <div key={index}><label>{child.name} <span className="form-text">(Grade {child.gradeLevel})</span></label></div>
                    ))}
                  </td>
                  <td>{new Intl.DateTimeFormat('en-US', {dateStyle: 'medium'}).format(new Date(account.createdAt))}</td>
                  <td>{account.lastLoggedinAt && (<span>{new Intl.DateTimeFormat('en-US', {dateStyle: 'medium'}).format(new Date(account.lastLoggedinAt))}</span>)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mb-3">
          <span className="me-2 link" title="Copy ALL emails to Clipboard" onClick={this.onCopyToClipboard}><Clipboard /> Copy ALL emails to Clipboard</span>
        </div>
      </div>
    );
  }

}

export default withRouter(Attendance);