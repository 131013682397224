import React, { Component } from "react";
import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';
import logo from "./intellichoice_bubble.png";

import Alerts from "./components/alerts.component";
import Profile from "./components/profile.component";
import RegisterAccount from "./components/register.component";
import VerifyEmail from "./components/verify-email.component";
import ForgotPassword from "./components/forgot-password.component";
import Login from "./components/login.component";
import Logout from "./components/logout.component";
import NavItem from "./nav-item";
import Welcome from "./components/welcome.component";
import Signups from "./components/signups.component";
import ResetPassword from "./components/reset-password.component";
import Branch from "./components/branch.component";
import BranchAttendance from "./components/branch-attendance.component";
import BranchMembers from "./components/branch-members.component";
import BranchNonregisteredStudents from "./components/branch-non-registered-students.component";
import Chapter from "./components/chapter.component";
import Reports from "./components/reports.component";

class App extends Component {
  constructor(props) {
    super(props);
    this.loggedIn = this.loggedIn.bind(this);
    this.setItemInSessionStorage = this.setItemInSessionStorage.bind(this);
    this.setArrayInSessionStorage = this.setArrayInSessionStorage.bind(this);
    this.getArrayFromSessionStorage = this.getArrayFromSessionStorage.bind(this);
    this.state = {
      token: window.sessionStorage.getItem("token"),
      name: window.sessionStorage.getItem("name"),
      permissions: this.getArrayFromSessionStorage("permissions"),
    };
    console.log(this.state.token);
  }
  loggedIn(data) {
    console.log("logged in");
    console.log(data);
    this.setItemInSessionStorage("token", data.token);
    this.setItemInSessionStorage("name", data.name);
    this.setArrayInSessionStorage("permissions", data.permissions);
    this.setState({ token: data.token, name: data.name, permissions: data.permissions})
  }
  setItemInSessionStorage(key, value) {
    if (value === null) {
      window.sessionStorage.removeItem(key);
    } else {
      window.sessionStorage.setItem(key, value);
    }
  }
  setArrayInSessionStorage(key, arr) {
    var value = null;
    arr?.map(item => {
      if (value === null) {
        value = item;
      } else {
        value = value + "," + item;
      }
    });
    this.setItemInSessionStorage(key, value);
  }
  getArrayFromSessionStorage(key) {
    const value = window.sessionStorage.getItem(key);
    var result = [];
    if (value !== null) {
      result = value.split(",");
    }
    return result;
  }
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container-fluid">
            <a href="https://intellichoice.org" className="navbar-brand">
              <img src={logo} className="logo" alt="Logo" />
              <span>IntelliChoice</span>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              {this.state.token && <span className="navbar-text">Welcome, {this.state.name}!</span>}
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {!this.state.token && <NavItem to="/register" text="Register" />}
                {!this.state.token && <NavItem to="/login" text="Login" />}
                {this.state.permissions?.includes("/reports") && <NavItem to="/reports" text="Reports" />}
                {this.state.permissions?.includes("/chapter") && <NavItem to="/chapter" text="Chapter Mgmt" />}
                {this.state.permissions?.includes("/branch") && <NavItem to="/branch" text="Branch Mgmt" />}
                {this.state.token && <NavItem to="/signups" text="Sessions" />}
                {this.state.token && <NavItem to="/profile" text="Profile" />}
                {this.state.token && <NavItem to="/logout" text="Logout" />}
              </ul>
              <span className="navbar-text">v2.7.2</span>
            </div>
          </div>
        </nav>

        <div className="container mt-3">
          {this.state.token && (<Alerts />)}
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/register" element={<RegisterAccount />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/login" element={<Login loggedIn={this.loggedIn} />} />
            <Route path="/logout" element={<Logout loggedIn={this.loggedIn} />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/signups" element={<Signups />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/branch" element={<Branch />}>
              <Route path="attendance" element={<BranchAttendance />} />
              <Route path="members" element={<BranchMembers />} />
              <Route path="non-registered-students" element={<BranchNonregisteredStudents />} />
            </Route>
            <Route path="/chapter" element={<Chapter />} />
            <Route path="/reports" element={<Reports />} />
          </Routes>
        </div>
      </div>
    );
  }
}

export default App;
