import React, { Component } from "react";
import { Clipboard } from 'react-bootstrap-icons';
import { withRouter } from "../common/with-router";
import AttendanceService from "../services/attendance.service";

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.onChangeAttendance = this.onChangeAttendance.bind(this);
    this.onCopyToClipboard = this.onCopyToClipboard.bind(this);
    this.onCopySignupsToClipboard = this.onCopySignupsToClipboard.bind(this);
    this.onAddClicked = this.onAddClicked.bind(this);
    this.onChangeSearchText = this.onChangeSearchText.bind(this);
    this.onAddSignup = this.onAddSignup.bind(this);
    this.state = {
      location: null,
      calendars: null,
      calendarId: null,
      signups: null,
      accounts: null,
      beingSavedId: null,
      searchText: "",
      searchTimer: null,
      isSearching: false,
      searchResult: [],
    };
    AttendanceService.listDates()
    .then(resp => {
      const location = resp.data.location;
      const calendars = resp.data.calendars;
      const calendarId = this.findFirstPastCalendarId(calendars.toReversed());
      this.setState({location: location, calendars: calendars, calendarId: calendarId});
      this.getSignups(calendarId);
    }).catch(this.handleError);
  }

  getSignups(calendarId) {
    this.setState({signups: null, beingSavedId: null})
    AttendanceService.listSignups(calendarId)
    .then(resp => {
      var emails = "";
      for (const signup of resp.data.signups) {
        const email = '"' + signup.account.firstName + ' ' + signup.account.lastName + '" <' + signup.account.email + '>';
        if (emails.indexOf(email) === -1) {
          if (emails !== "") {
            emails = emails + ', ';
          }
          emails = emails + email;
        }
      }
      this.setState({signups: resp.data.signups, signupEmails: emails});
    })
    .catch(this.handleError);
  }

  handleError(e) {
    if (e.response?.status === 403) {
      this.props.router.navigate("/login");
    } else {
      console.log(e);
    }
  }

  findFirstPastCalendarId(calendars) {
    const now = new Date();
    const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    for (var index = 0; index < calendars.length; ++index) {
      if (new Date(calendars[index].startTime) > yesterday) {
        return calendars[index].id;
      }
    }
    return calendars.length === 0 ? null : calendars[calendars.length - 1].id;
  }

  onChangeLocation(e) {
    const calendarId = parseInt(e.target.value);
    this.setState({calendarId: calendarId, signups: null});
    this.getSignups(calendarId);
  }

  onChangeAttendance(e) {
    const attended = e.target.value === "1";
    const signupId = parseInt(e.target.name.split("-")[1]);
    const signup = this.findSignup(signupId);
    const currentAttended = signup.attended;
    if (signup.attended === attended) {
      signup.attended = null;
    } else {
      signup.attended = attended;
    }
    this.setState({signups: this.state.signups, beingSavedId: signupId});
    AttendanceService.updateAttendance(signupId, signup.attended)
    .then(resp => {
      this.setState({beingSavedId: null});
    })
    .catch(e => {
      signup.attended = currentAttended;
      this.setState({signups: this.state.signups, beingSavedId: null});
      this.handleError(e);
    });
  }

  onCopyToClipboard() {
    navigator.clipboard.writeText(this.state.emails);
  }

  onCopySignupsToClipboard() {
    navigator.clipboard.writeText(this.state.signupEmails);
  }

  findSignup(id) {
    for (var index = 0; index < this.state.signups.length; ++index) {
      if (this.state.signups[index].id === id) {
        return this.state.signups[index];
      }
    }
    return null;
  }

  formatDatetime(datetime) {
    return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric'}).format(new Date(datetime));
  }

  signup2name(signup) {
    return "signup-" + signup.id;
  }

  signup2id(signup, yes) {
    return this.signup2name(signup) + "-" + (yes ? "1" : "0");
  }

  getGradeLevel(signup) {
    var gradeLevel = null;
    if (signup.account.role === "Student") {
      gradeLevel = signup.account.gradeLevel;
    }
    else if (signup.child !== null) {
      gradeLevel = signup.child.gradeLevel;
    }
    return gradeLevel === null ? "" : gradeLevel;
  }

  findSelectedCalendar() {
    for (const calendar of this.state.calendars) {
      if (calendar.id === this.state.calendarId) {
        return calendar;
      }
    }
    return null;
  }

  isSelectedSessionInFuture() {
    if (this.state.calendars === null || this.state.calendars.length === 0) {
      return false;
    }
    const now = new Date(new Date().getTime() + 60 * 60 * 1000);
    const sessionDate = new Date(this.findSelectedCalendar().startTime);
    return sessionDate > now;
  }

  search(q) {
    this.setState({isSearching: true});
    AttendanceService.searchMembers(this.state.calendarId, q)
    .then(resp => {
      this.setState({isSearching: false, searchResult: resp.data.result});
    });
  }

  onAddClicked(e) {
    this.setState({searchText: ""});
    this.search("");
  }

  onChangeSearchText(e) {
    if (this.state.searchTimer !== null) {
      clearTimeout(this.state.searchTimer);
    }
    const searchText = e.target.value;
    var searchTimer = setTimeout(() => this.search(searchText), 200);
    this.setState({ searchText: searchText, searchTimer: searchTimer });
  }

  onAddSignup(index) {
    const searchResult = this.state.searchResult;
    searchResult[index].state = "Adding...";
    this.setState({searchResult: searchResult});
    AttendanceService.addSignup(this.state.calendarId, this.state.searchResult[index].id, this.state.searchResult[index].childId)
    .then(resp => {
      const searchResult = this.state.searchResult;
      searchResult[index].state = "Added";
      this.setState({searchResult: searchResult});
      this.getSignups(this.state.calendarId);
    });
  }

  render() {
    return (
      <div>
        <div>
          <form className="needs-validation" noValidate ref={this.formRef}>
            <div className="mb-3">
              <select className="form-select" value={this.state.calendarId ? this.state.calendarId : ""} onChange={this.onChangeLocation}>
                {!this.state.calendars && (<option>Loading...</option>)}
                {this.state.calendars?.length === 0 && (<option>No sessions yet</option>)}
                {this.state.calendars?.map(calendar => (
                  <option key={calendar.id} value={calendar.id}>{this.formatDatetime(calendar.startTime)}</option>
                ))}
              </select>
            </div>
            <h5 className="mb-3">
              Signups
              {this.state.beingSavedId !== null && (<span className="small badge rounded-pill text-bg-warning">(Saving...)</span>)}
              {this.isSelectedSessionInFuture() && (<span className="small badge rounded-pill text-bg-warning">(Cannot record attendance yet because the selected session is in the future)</span>)}
            </h5>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Child</th>
                    <th>Grade</th>
                    <th className="text-center">Attended?</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.signups?.length === 0 && (
                    <tr><td colSpan="6">No signups</td></tr>
                  )}
                  {this.state.signups?.map(signup => (
                    <tr key={signup.id}>
                      <td>
                        {signup.account.firstName} {signup.account.lastName}
                      </td>
                      <td>
                        {signup.account.email}
                      </td>
                      <td>
                        {signup.account.role}
                      </td>
                      <td>
                        {signup.child?.name}
                      </td>
                      <td>
                        {this.getGradeLevel(signup)}
                      </td>
                      <td className="text-center">
                        {this.isSelectedSessionInFuture() || (
                          <div>
                            <div className="form-check form-check-inline">
                              <input id={this.signup2id(signup, true)} name={this.signup2name(signup)} value="1" type="checkbox" checked={signup.attended === true} className="form-check-input" onChange={this.onChangeAttendance} disabled={this.state.beingSavedId !== null ? "disabled" : ""} />
                              <label htmlFor={this.signup2id(signup, true)} className="form-check-label">Yes</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input id={this.signup2id(signup, false)} name={this.signup2name(signup)} value="0" type="checkbox" checked={signup.attended === false} className="form-check-input" onChange={this.onChangeAttendance} disabled={this.state.beingSavedId !== null ? "disabled" : ""} />
                              <label htmlFor={this.signup2id(signup, false)} className="form-check-label">No</label>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {this.isSelectedSessionInFuture() || (
              <div className="mb-3">
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addSignupModal" onClick={this.onAddClicked}>Add</button>
              </div>
            )}
          </form>
          <div>
            <span className="me-2 link" title="Copy to Clipboard" onClick={this.onCopySignupsToClipboard}><Clipboard /> Copy emails to Clipboard</span>
          </div>
        </div>
        <div className="modal fade" id="addSignupModal" tabIndex="-1" aria-labelledby="addSignupModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="addSignupModalLabel">Add Signup</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="row mb-3">
                  <div className="col-auto">
                    <label className="col-form-label">Search:</label>
                  </div>
                  <div className="col-auto">
                    <input type="text" className="form-control" value={this.state.searchText} onChange={this.onChangeSearchText} />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Child</th>
                        <th>Branch</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.isSearching && (
                        <tr>
                          <td colSpan="5">Searching...</td>
                        </tr>
                      )}
                      {!this.state.isSearching && this.state.searchResult.map((row, index) => (
                        <tr key={index}>
                          <td>{row.firstName} {row.lastName}<br/><span className="fw-lighter">({row.email})</span></td>
                          <td>{row.role}</td>
                          <td>{row.childName}</td>
                          <td>{row.locationName}</td>
                          <td>
                            {row.state === undefined && (
                              <button type="button" className="btn btn-primary btn-sm" data-id={row.id} onClick={() => this.onAddSignup(index)}>Add</button>
                            )}
                            {row.state !== undefined && (<span>{row.state}</span>)}
                          </td>
                        </tr>
                      ))}
                      {!this.state.isSearching && this.state.searchResult.length === 0 && (
                        <tr>
                          <td colSpan="5">No match</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default withRouter(Attendance);