import React, { Component } from "react";
import { withRouter } from "../common/with-router";
import ProfileParent from "./profile-parent.component";
import ProfileStudent from "./profile-student.component";
import ProfileTutor from "./profile-tutor.component";

class Profile extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div><ProfileTutor /></div>
    );
  }
}

export default withRouter(Profile);