import React, { Component } from "react";
import { Check, Pencil, PlusSquareFill, Trash, X } from 'react-bootstrap-icons';
import { withRouter } from "../common/with-router";
import AttendanceService from "../services/attendance.service";
import SelectGradeLevel from "./select-grade-level.component"

class BranchNonregisteredStudents extends Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onGradeLevelChange = this.onGradeLevelChange.bind(this);
    this.onEditSave = this.onEditSave.bind(this);
    this.onEditCancel = this.onEditCancel.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.state = {};
    AttendanceService.listNonRegisteredStudents()
    .then(resp => {
      this.setState({students: resp.data.students});
    })
    .catch(this.handleError);
  }

  onEdit(e) {
    const id = this.getStudentId(e.target);
    const index = this.getIndexById(id);
    const student = this.state.students[index];
    this.setState({edit: {id: id, name: student.name, gradeLevel: student.gradeLevel}});
  }

  onNameChange(e) {
    const edit = this.state.edit;
    edit.name = e.target.value;
    this.setState({edit: edit});
  }

  onGradeLevelChange(gradeLevel) {
    const edit = this.state.edit;
    edit.gradeLevel = gradeLevel;
    this.setState({edit: edit});
  }

  onEditSave(e) {
  	if (this.state.edit.id === null) {
      AttendanceService.addNonRegisteredStudent(this.state.edit.name, this.state.edit.gradeLevel)
      .then(resp => {
        const students = this.state.students;
      	students.push(resp.data);
      	this.setState({students: students, edit: undefined});
      })
      .catch(this.handleError);  		
  	} else {
      AttendanceService.updateNonRegisteredStudent(this.state.edit.id, this.state.edit.name, this.state.edit.gradeLevel)
      .then(resp => {
        const students = this.state.students;
        const index = this.getIndexById(resp.data.id);
        students[index].name = resp.data.name;
        students[index].gradeLevel = resp.data.gradeLevel;
      	this.setState({students: students, edit: undefined});
      })
      .catch(this.handleError);  		
  	}
  }

  onEditCancel(e) {
    this.setState({edit: undefined});
  }

  onDelete(e) {
    const id = this.getStudentId(e.target);
    this.setState({delete: id});
  }

  onDeleteConfirm(e) {
    AttendanceService.deleteNonRegisteredStudent(this.state.delete)
    .then(resp => {
      const index = this.getIndexById(this.state.delete);
      const students = this.state.students;
      students.splice(index, 1);
      this.setState({students: students, delete: undefined});
    })
    .catch(this.handleError);
  }

  onDeleteCancel(e) {
    this.setState({delete: undefined});
  }

  onAdd(e) {
    this.setState({edit: {id: null, name: "", gradeLevel: null}});
  }

  getStudentId(node) {
    const span = this.findSpan(node);
    return parseInt(span.attributes["data-key"].nodeValue);
  }

  findSpan(node) {
    while (node.nodeName !== "SPAN") {
      node = node.parentNode;
    }
    return node;
  }

  getIndexById(id) {
    for (var index = 0; index < this.state.students.length; ++index) {
      const student = this.state.students[index];
      if (student.id === id) {
        return index;
      }
    }
    return null;
  }

  handleError(e) {
    if (e.response?.status === 403) {
      this.props.router.navigate("/login");
    } else {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        <div className="table-responsive mb-3">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Name (First Last)</th>
                <th>Grade</th>
                <th>Last Attended On</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.students === undefined && (
                <tr>
                  <td colSpan="3">Loading...</td>
                </tr>
              )}
              {this.state.students?.map(student => (
                <tr key={student.id} className="align-middle">
                  <td>
                    {this.state.edit?.id === student.id && (
                      <input type="text" className="form-control" value={this.state.edit.name} onChange={this.onNameChange} />
                    )}
                    {this.state.edit?.id !== student.id && (
                      <span>{student.name}</span>
                    )}
                  </td>
                  <td>
                    {this.state.edit?.id === student.id && (
                      <SelectGradeLevel gradeLevel={this.state.edit.gradeLevel} onChange={this.onGradeLevelChange} />
                    )}
                    {this.state.edit?.id !== student.id && (
                      <span>{student.gradeLevel === 0 ? "K" : student.gradeLevel}</span>
                    )}
                  </td>
                  <td>{student.lastAttendedAt === null ? "-" : new Intl.DateTimeFormat('en-US', {dateStyle: 'medium'}).format(new Date(student.lastAttendedAt))}</td>
                  <td>
                    {this.state.edit?.id === undefined && this.state.delete === undefined && (
                      <div>
                        <span className="text-success me-2 link" title="Edit" onClick={this.onEdit} data-key={student.id}>
                          <Pencil />
                        </span>
                        <span className="text-danger me-2 link" title="Delete" onClick={this.onDelete} data-key={student.id}>
                          <Trash />
                        </span>
                      </div>
                    )}
                    {this.state.edit?.id === student.id && (
                      <div className="fs-3">
                        {this.state.edit.name.trim().length > 0 && this.state.edit.gradeLevel !== null && (
                          <span className="text-success me-2 link" title="Save changes" onClick={this.onEditSave}>
                            <Check />
                          </span>
                        )}
                        <span className="text-danger me-2 link" title="Undo changes" onClick={this.onEditCancel}>
                          <X />
                        </span>
                      </div>
                    )}
                    {this.state.delete === student.id && (
                      <div>
                        <span className="me-2">Sure to delete?</span>
                        <span className="fs-3 text-success me-2 link" title="Delete" onClick={this.onDeleteConfirm}>
                          <Check />
                        </span>
                        <span className="fs-3 text-danger me-2 link" title="Cancel" onClick={this.onDeleteCancel}>
                          <X />
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
              {this.state.edit?.id === null && (
                <tr>
                  <td>
                    <input type="text" className="form-control" value={this.state.edit.name} onChange={this.onNameChange} />
                  </td>
                  <td>
                    <SelectGradeLevel onChange={this.onGradeLevelChange} />
                  </td>
                  <td>
                  </td>
                  <td>
                    <div className="fs-3">
                      {this.state.edit.name.trim().length > 0 && this.state.edit.gradeLevel !== null && (
                        <span className="text-success me-2 link" title="Save changes" onClick={this.onEditSave}>
                          <Check />
                        </span>
                      )}
                      <span className="text-danger me-2 link" title="Undo changes" onClick={this.onEditCancel}>
                        <X />
                      </span>
                    </div>
                  </td>
                </tr>
              )}
              {this.state.students?.length === 0 && (
              	<tr>
              	  <td colSpan="4">
              	  	No non-registered students added yet
              	  </td>
              	</tr>
              )}
            </tbody>
            {this.state.edit === undefined && this.state.delete === undefined && (
              <tfoot>
                <tr>
                  <td colSpan="4">
                    <div>
                      <span className="link" onClick={this.onAdd}>
                        <span className="me-2"><PlusSquareFill /></span>
                        <span>Add new student</span>
                      </span>
                    </div>
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
        <div id="non-registered-students-help" className="accordion mb-3">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                How to use the Non-registered Students Feature?
              </button>
            </h2>
              <div id="collapseOne" className={"accordion-collapse collapse" + (this.state.students?.length === 0 ? " show" : "")} data-bs-parent="#non-registered-students-help">
                <div className="accordion-body">
                  <strong>Managers</strong>, you can manage non-registered students in your branch under this tab in order to keep track of these students&apos; attendance.
                  New students can be added by providing their names and grades.
                  Once a student has been added, you can add him or her to the attendee list the same way as you would add another student or tutor who has not signed up for a session but attended.
                  <br/>Keeping track of these students&apos; attendance helps Intellichoice to get an accurate attendance count and therefore it is an important goal for all of us.
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BranchNonregisteredStudents);
