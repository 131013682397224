import React, { Component } from "react";
import { Outlet } from 'react-router-dom';

import { withRouter } from "../common/with-router";
import AttendanceService from "../services/attendance.service";
import NavItem from "../nav-item";

class Branch extends Component {
  constructor(props) {
    super(props);
    this.handleError = this.handleError.bind(this);
    AttendanceService.getBranch()
    .then(resp => {
      this.setState({name: resp.data.name});
    }).catch(this.handleError);
  }

  componentDidUpdate() {
    if (this.props.router.location.pathname === "/branch") {
      this.props.router.navigate("/branch/attendance", {replace: true});
    }
  }

  handleError(e) {
    if (e.response?.status === 403) {
      this.props.router.navigate("/logout");
    } else {
      console.log(e);
    }
  }

  render() {
    return (
      <div className="fancy-box">
        <h1 className="mb-3">Branch Mgmt - {this.state?.name}</h1>
        <ul className="nav nav-tabs mb-3">
          <NavItem to="attendance" text="Attendance" />
          <NavItem to="members" text="Members" />
          <NavItem to="non-registered-students" text="Non-registered Students" />
        </ul>
        <Outlet />
      </div>
    );
  }

}

export default withRouter(Branch);