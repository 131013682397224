import http from "../http-common";

class AttendanceService {
  getBranch() {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances", {params: {token: token}});
  }

  listDates() {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/dates", {params: {token: token}});
  }

  listMembers(calendarId) {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/members/" + calendarId, {params: {token: token}});
  }

  searchMembers(calendarId, q) {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/member-search/" + calendarId, {params: {token: token, q: q}});
  }
  listSignups(calendarId) {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/signups/" + calendarId, {params: {token: token}});
  }
  updateAttendance(signupId, attended) {
    const token = window.sessionStorage.getItem("token");
    return http.patch("/attendances/" + signupId, {attended: attended}, {params: {token: token}});
  }
  addSignup(calendarId, accountId, childId) {
    const token = window.sessionStorage.getItem("token");
    return http.post("/attendances", {calendarId: calendarId, accountId: accountId, childId: childId}, {params: {token: token}});
  }

  // non-registered students
  listNonRegisteredStudents() {
    var token = window.sessionStorage.getItem("token");
    return http.get("/attendances/non-registered-students", {params: {token: token}});
  }

  addNonRegisteredStudent(name, gradeLevel) {
    var token = window.sessionStorage.getItem("token");
    return http.post("/attendances/non-registered-students", {name: name, gradeLevel: gradeLevel}, {params: {token: token}});
  }

  updateNonRegisteredStudent(id, name, gradeLevel) {
    var token = window.sessionStorage.getItem("token");
    return http.patch("/attendances/non-registered-students/" + id, {name: name, gradeLevel: gradeLevel}, {params: {token: token}});
  }

  deleteNonRegisteredStudent(id) {
    var token = window.sessionStorage.getItem("token");
    return http.delete("/attendances/non-registered-students/" + id, {params: {token: token}});
  }
}

export default new AttendanceService();
